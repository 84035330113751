import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { options } from "../components/parser"
import parse from "html-react-parser"
import { TextCard, SoliWhite, SoliCard, BigText } from "../components/styles"
import { FlyFloat } from "../components/headerlinks"

const RightSide = ({
  data: {
    id,
    html,
    frontmatter: { title, bild, bildtext },
  },
}) => (
  <SoliCard id={id}>
    <FlyFloat />
    <SoliWhite>{title}</SoliWhite>
    <BigText>{parse(html, options)}</BigText>
  </SoliCard>
)

const Vertrieb = ({ data }) => {
  return (
    <Layout
      /* leftside={<LeftSide data={Vertrieblinks()}/>} */
      rightside={<RightSide data={data.Vertriebrechts} />}
    >
      <TextCard>{parse(data.Vertrieb.html, options)}</TextCard>
    </Layout>
  )
}

export default Vertrieb

export const query = graphql`
  query VertriebQuery {
    Vertrieb: markdownRemark(frontmatter: { location: { eq: "Vertrieb" } }) {
      id
      frontmatter {
        online
      }
      html
    }
    Vertriebrechts: markdownRemark(
      frontmatter: { location: { eq: "Vertriebrechts" } }
    ) {
      id
      frontmatter {
        bild
        bildtext
        location
        online
        title
      }
      html
    }
  }
`
